.blue {
  color:#5F5AA2 !important;
}
.darkblue {
  color:#454176 !important;
}
.green {
  color:#7DAF9C !important;
}
.red {
  color:#DD7373 !important;
}
.yellow {
  color:#F1DB4B !important;
}
.white {
  color:#FFFFFF !important;
}

.blueBackground {
  background-color:#5F5AA2 !important;
}
.transparentBackground {
  background-color:transparent !important;
}
.greenBackground {
  background-color:#7DAF9C !important;
}
.redBackground {
  background-color:#DD7373 !important;
}
.yellowBackground {
  background-color:#F1DB4B !important;
}
.whiteBackground {
  background-color:#FFFFFF !important;
}

.blueBorder {
  border-color:#5F5AA2 !important;
}
.greenBorder {
  border-color:#7DAF9C !important;
}
.redBorder {
  border-color:#DD7373 !important;
}
.yellowBorder {
  border-color:#F1DB4B !important;
}

.blueStroke {
  stroke:#5F5AA2 !important;
}
.greenStroke {
  stroke:#7DAF9C !important;
}
.redStroke {
  stroke:#DD7373 !important;
}
.yellowStroke {
  stroke:#F1DB4B !important;
}
.#323232Stroke {
  stroke:#323232 !important;
}
.whiteStroke {
  stroke:#FFFFFF !important;
}
