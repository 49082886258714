.carouselSection{
  height:50vh;
  position: relative;
  padding:2em;
  padding-top:0;
}

.carouselSection > h2{
  margin-top:0;
}

.carouselSection a {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.carouselSection img {
  border-radius:2em;
  height:100%;
  object-fit: contain;
}

.swiper-container{
  padding-bottom:0;
}

.swiper-button-next, .swiper-button-prev{
  color:rgba(255,255,255,1);
  top:50%;
  -webkit-filter: drop-shadow(2px 2px 2px rgba(0,0,0,0.25));
  -moz-filter: drop-shadow(2px 2px 2px rgba(0,0,0,0.25));
  -ms-filter: drop-shadow(2px 2px 2px rgba(0,0,0,0.25));
  -o-filter: drop-shadow(2px 2px 2px rgba(0,0,0,0.25));
}

.swiper-button-prev{
  left: 2em;
}
.swiper-button-next{
  right: 2em;
}

.swiper-pagination {
  position: absolute;
  bottom:0 !important;
  width: calc(100% - 4em);
}

@media only screen and (max-width: 900px) {
  .swiper-button-prev{
    left: 10px;
  }
  .swiper-button-next{
    right: 10px;
  }
  .swiper-pagination {
    display:flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    height: 35px;
    left: 50% !important;
    transform: translateX(-50%);
  }
}

.swiper-pagination-bullet {
  width: 0.5em;
  height: 0.5em;
}
.swiper-pagination-bullet-active {
  background: #DD7373;
}

.swiper-slide{
  display:flex;
  align-items: center;
  justify-content: center;
}

.carouselImage{
  width:100%;
  border-radius:.5em;
}
