/* <editor-fold>--------------------------------------NAVBAR TOGGLE */

.fixedButtonsWrapper {
  position: fixed;
  top: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 99999;
  width: 100%;
}

.navbarFloatLeft {
  display: block;
  margin-left: 10px;
  width: 50px;
  height: 50px;
  background: url("./images/simplelogoColor.webp");
  background-size: 50px;
  user-select: none;
  -webkit-user-drag: none;
}

.navbarFloatLeft:hover {
  background: url("./images/simplelogoBlack.webp");
  background-size: 50px;
}

.navbarFloatRight {
  display: flex;
  align-items: center;
}

#emailToggleButtonMobile {
  height: 20px;
  width: 100%;
  -webkit-filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.25));
  -moz-filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.25));
  -ms-filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.25));
  -o-filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.25));
}

#navbarOpenClose {
  height: 42px;
  width: 58px;
  cursor: pointer;
  color: #222222;
  font-size: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: 0;
  -webkit-filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.25));
  -moz-filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.25));
  -ms-filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.25));
  -o-filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.25));
}

.navbarClass:not(.is-active) #navbarOpenClose {
  background: #fff;
  border-radius: 4px;
}

/* </editor-fold>

/* <editor-fold>--------------------------------------NAVBAR */

.navbarMain {
  /* background-color:rgba(255,255,255,.95); */
  background-image: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 40vw,
    rgba(255, 255, 255, 1) 100%
  );
  left: 100%;
  top: 0;
  position: fixed;
  width: 125%;
  height: 100%;
  z-index: 99998;
  transition-property: left;
  transition-duration: 0.25s;
  overflow: hidden;
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  .navbarMain {
    background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 35vw,
      rgba(255, 255, 255, 1) 100%
    );
  }
}

@media only screen and (min-width: 900px) and (max-width: 1200px) {
  .navbarMain {
    background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 33vw,
      rgba(255, 255, 255, 1) 100%
    );
  }
}

@media only screen and (max-width: 900px) {
  .navbarMain {
    background-image: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 1) 8vw,
      rgba(255, 255, 255, 1) 100%
    );
  }
}

.navbarMain.is-active {
  left: -25%;
}

.navbarChildrenWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  height: 100%;
  width: 100vw;
  position: absolute;
  right: 0;
  top: 0;
  /* fade in the menus after navbar scrolls in */
  opacity: 0;
  transition: none;
}

.navbarMain.is-active .navbarChildrenWrapper {
  opacity: 1 !important;
  transition-property: opacity;
  transition-duration: 0.25s;
  transition-delay: 0.25s;
}

.navbarChildren {
  height: 50%;
  margin: auto;
}

.navbarClass p,
.navbarClass a:not(.defaultButton),
.navbarClass h1,
.navbarClass h2,
.navbarClass h3 {
  color: #323232;
}
.navbarCenterBorder {
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  height: 50%;
  width: 0;
}

.navbarClass {
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 900px) {
  .navbarMain {
    flex-direction: column;
  }
  .navbarMain .navbarChildren {
    opacity: 0;
    width: 0;
  }
  .navbarMain .navbarChildren.is-active {
    width: calc(100% - 4em) !important;
    opacity: 1 !important;
    transition-property: opacity;
    transition-duration: 0.25s;
  }
}

/* </editor-fold> */

/* <editor-fold>--------------------------------------NAVBAR LEFT */

.navbarLeftWrapper {
  display: flex;
  width: calc(50% - 4em);
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.navbarButtonWrapper {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  color: #323232;
}

.navbarButton {
  font-family: "Rowdies-Regular";
  padding: 0.5em;
  padding-top: 0.25em;
  padding-bottom: 0.25em;
  font-size: 2em;
  text-align: left;
  text-decoration: none;
  text-transform: none;
  user-select: none;
  -webkit-user-drag: none;
  border: 0;
  margin: 0;
  border-left: 4px solid transparent;
}

.navbarButton.is-active {
  border-left: 4px solid #dd7373;
  color: #828282;
}

.navbarButton:hover {
  color: #828282;
}

.navbarButton:active {
  color: #b3b3b3;
}

.languageChangeWrapper {
  font-size: 1.25em;
  padding-left: 1em;
  padding-top: 3em;
}

.languageChangeSpan {
  font-family: "Rowdies-Regular";
  cursor: pointer;
}

/* </editor-fold> */

/* <editor-fold>--------------------------------------NAVBAR RIGHT */

.navbarRightWrapper {
  display: flex;
  gap: 5px;
}

.emailWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.formPrompt {
  font-size: 1.5em;
}

.formWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 600px;
}

.forminputText {
  max-width: 500px;
  font-size: 1.1em;
}

.forminputText a {
  color: #323232;
}

.emailInput {
  padding: 1em;
  font-family: "Roboto Light";
  font-size: 1em;
  margin-bottom: 2em;
  border: 0;
  border-bottom: 1px solid #323232;
  outline: 0;
  width: 100%;
  color: #323232;
  text-align: center;
  line-height: 100%;
}

.subscribeButton {
  padding-left: 4em;
  padding-right: 4em;
  font-size: 1em;
}

.artbookMockup {
  width: 100%;
}

@media only screen and (min-width: 900px) {
  .artbookMockup {
    width: 50% !important;
  }
}

/* </editor-fold> */

/* <editor-fold>--------------------------------------MAIN PAGE EMAIL */

.mainpageEmail .formWrapper {
  flex-direction: row;
  margin-bottom: 1em;
  -webkit-filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.25));
  -moz-filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.25));
  -ms-filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.25));
  -o-filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.25));
}

.mainpageEmail .emailInput {
  width: 300px;
  margin-bottom: 0;
  border: 1px solid #fff !important;
  border-radius: 4px 0 0 4px;
  padding: 10px !important;
  height: 47px;
  text-align: left !important;
}

.mainpageEmail .subscribeButton {
  padding: 10px 15px 10px 10px !important;
  border-radius: 0 4px 4px 0;
  width: 50%;
  height: 47px;
  background-color: #7daf9c;
}

@media only screen and (max-width: 900px) {
  .mainpageEmail .formWrapper {
    margin-bottom: 0;
  }

  .mainpageEmail .emailInput {
    font-size: 16px;
    width: 50%;
    height: 35px;
  }

  .mainpageEmail .subscribeButton {
    font-size: 0.85em;
    width: 50%;
    height: 35px;
    padding-left: 5px;
    padding-right: 5px;
  }

  .mainpageEmail:not(.secondMainpageEmail) .forminputText {
    font-size: 1em !important;
    margin-bottom: 0.25em;
    -webkit-filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.25));
    -moz-filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.25));
    -ms-filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.25));
    -o-filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.25));
  }
}

/* low height mobile */
@media only screen and (max-height: 750px) {
  .mainpageEmail .emailInput {
    font-size: 0.65em;
    width: 90%;
  }

  .mainpageEmail .subscribeButton {
    font-size: 0.65em;
    width: 65%;
    padding: 5px 10px 5px 5px !important;
  }
}

/* </editor-fold> */
