.characterDecksContainer {
  width: 60%;
  height: 100%;
  overflow:hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding:0 !important;
  z-index:9;
}

.charactersDeckRow {
  width:100%;
  height:20%;
  display:flex;
  justify-content: center;
  align-items: center;
  padding:2em;
}

.characterCardWrapper {
  position:absolute;
}

.characterCardWrapper a {
  color: #323232;
  text-decoration: none;
  height:100%;
  width:100%;
  display:block;
}

/* small desktop */
@media only screen and (min-width: 1200px) and (max-width: 1600px) {

}

/* tablet */
@media only screen and (min-width: 900px) and (max-width: 1200px) {

}

/* mobile */
@media only screen and (max-width: 900px) {
  .characterDecksContainer {
    width: 100%;
    position:absolute;
    height:100%;
  }
}
