/* <editor-fold>------------------------------------------------------------------GLOBALS */

html {
  height: -webkit-fill-available;
}

body {
  margin: 0;
  padding: 0;
  border: 0;
  font-family: "Rowdies-Regular";
  text-align: center;
  letter-spacing: 1px;
  font-size: 1.1em;
  color: #323232;
  overflow-x: hidden;
  position: relative;
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

.is-hidden {
  display: none !important;
}

h1,
h2,
h3,
h4,
h5,
p,
li,
ul,
a,
span {
  line-height: 150%;
}

h2,
h2 a {
  font-family: "Rowdies-Regular";
}

h3,
p,
a,
input,
li {
  font-family: "Roboto Light";
}

p,
li {
  opacity: 0.85;
}

span.is-bold {
  font-family: "Rowdies-Light";
}

.prelineWhiteSpace {
  white-space: pre-line;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  -webkit-tap-highlight-color: transparent; /* Remove tap highlight */
}

/* to let the image underneath be clicked and dragged */
.nopointerevent {
  pointer-events: none;
}

.fantasyEmphasis {
  background: linear-gradient(
    -45deg,
    #5f5aa2 20%,
    #f1db4b 40%,
    #7daf9c 60%,
    #dd7373 80%
  );
  background-size: 200% auto;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: animated_text 5s ease-in-out infinite;
}

@keyframes animated_text {
  0% {
    background-position: 0px 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0px 50%;
  }
}

.mirrored {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

@media only screen and (min-width: 901px) {
  .is-hidden-desktop {
    display: none !important;
  }
}

@media only screen and (max-width: 900px) {
  .is-hidden-mobile {
    display: none !important;
  }
}

.stickerTasksWrapper {
  text-align: left;
  font-size: 1.5em;
}

.stickerTasksWrapper .taskWrapper:not(:last-child) {
  margin-bottom: 1em;
  margin-top: 1em;
}

.stickerTasksWrapper div {
  display: flex;
}

.taskWrapper,
.taskWrapper label {
  cursor: pointer;
}

.checkWrapper {
  margin-top: 0.15em;
}

.stickerTasksWrapper input {
  margin-right: 1em;
  width: 1.5em;
  height: 1.5em;
}

/* </editor-fold> */

/* <editor-fold>------------------------------------------------------------------BUTTONS */

.defaultButton {
  font-family: "Rowdies-Regular";
  cursor: pointer;
  padding: 10px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: #323232;
  color: #fff;
  border-radius: 4px;
  text-decoration: none;
  border: 1px solid transparent;
  box-shadow: 1px 1px 4px 0px #323232;
  line-height: 125%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: fit-content;
}

.defaultButton.is-large {
  font-size: 1.2em;
  padding: 15px;
  min-width: 400px;
}

.defaultButton.liveKS {
  background-color: #05ce78;
}

@media only screen and (max-width: 900px) {
  .defaultButton {
    margin: auto;
    width: calc(100% - 10px);
    font-size: 0.85em;
    min-width: 100px !important;
  }
}

.defaultButton:active {
  background-color: #474747;
}

.defaultButton.is-green {
  background-color: #7daf9c;
}

.defaultButton.is-blue {
  background-color: #5f5aa2;
}

.defaultButton.is-red {
  background-color: #d95555;
}

.defaultButton.is-yellow {
  background-color: #f1db4b;
  color: #323232;
}

.defaultButton.is-inverted {
  background-color: #fff;
  color: #323232;
}

.defaultButton.is-inverted:active {
  background-color: #f0f0f0;
}

.defaultButton.is-bordered-black {
  border: 1px solid #323232;
}

.defaultButton.is-bordered-white {
  border: 1px solid #fff;
}

.defaultButton.is-bordered-transparent {
  border: 1px solid rgba(0, 0, 0, 0);
}

.defaultButton.is-shadowless {
  box-shadow: none;
}

.defaultButtonIcon {
  width: 25px;
  height: 25px;
  margin-right: 10px;
}

.defaultButton.is-reversed .defaultButtonIcon {
  margin-right: 0;
  margin-left: 10px;
}

.defaultButton.is-animated img {
  animation-name: animedButtonSVG;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes animedButtonSVG {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(5px, 0px);
  }
  100% {
    transform: translate(-0, 0px);
  }
}

/* </editor-fold> */

/* <editor-fold>------------------------------------------------------------------CONTENT BLOCKS */

.content {
  text-align: center;
  display: block;
  width: 100%;
  padding-bottom: 15vh;
  margin: auto;
  overflow: hidden;
}

.content.max-width {
  max-width: 900px;
}

.content p {
  margin-top: 0;
}

.contentWrapper {
  margin: auto;
  display: block;
  margin-top: 10px;
}

.subcontentWrapper {
  max-width: 900px;
  margin: auto;
  padding-left: 2em;
  padding-right: 2em;
  white-space: pre-line;
}

.subcontentWrapper h1,
.subcontentWrapper h2,
.subcontentWrapper h3 {
  font-weight: normal;
}

@media only screen and (max-width: 900px) {
  .subcontentWrapper.margin-top {
    margin-top: 5vh;
  }
  .subcontentWrapper.padding-top {
    padding-top: 5vh;
  }
}

.subcontentWrapper.min-width {
  max-width: 600px;
}

.subcontentWrapper.is-wider {
  max-width: 1250px;
}

.subcontentWrapper.border-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
  margin-bottom: 20px;
}

.subcontentWrapper.border-top {
  border-top: 1px solid rgba(0, 0, 0, 0.15);
  margin-top: 20px;
}

.subtitle {
  position: relative;
  word-break: break-word;
  font-weight: normal;
}

.subsubtitle {
  margin-top: 0px;
  position: relative;
  font-weight: normal;
}

/* </editor-fold> */

/* <editor-fold>------------------------------------------------------------------TITLE */

.floating {
  animation-name: floating;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes floating {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, 5px);
  }
  100% {
    transform: translate(0, -0px);
  }
}

.floating-counter {
  animation-name: floating-counter;
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
}

@keyframes floating-counter {
  0% {
    transform: translate(0, 0px);
  }
  50% {
    transform: translate(0, -5px);
  }
  100% {
    transform: translate(0, -0px);
  }
}

.lcmContainer {
  margin: auto;
  padding-top: 5px;
  width: 250px;
}

.lcmImage {
  background: transparent;
  width: 100%;
  margin: 0;
  min-height: 271.66px;
}

.sharehomegame {
  text-align: right;
  width: 109%;
  margin-top: -10px !important;
  font-size: 1.3em;
  font-family: "Rowdies-Regular";
}

/* </editor-fold> */
