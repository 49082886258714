@font-face {
  font-family: "Rowdies-Light";
  src: url("../../fonts/Rowdies-Light.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Rowdies-Regular";
  src: url("../../fonts/Rowdies-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Rowdies-Bold";
  src: url("../../fonts/Rowdies-Bold.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto Light";
  src: url("../../fonts/Roboto-Light.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Roboto Regular";
  src: url("../../fonts/Roboto-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
