.parallaxSectionXOverflow {
  position: absolute;
  top: 0;
  width: 100%;
  height: 300vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 5vh;
  padding-bottom: 5vh;
  overflow: hidden;
}

.parallaxSection {
  height: 100%;
  height: 100vh;
  height: 100dvh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.parallaxRow {
  display: flex;
  justify-content: center;
  width: 100%;
}

.parallaxWrapper {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  padding: 0.5em;
}
